<script lang="ts" setup>
import { computed } from 'vue'
import type User from '~/src/Domain/Identity/User'
import useIdentityNavigation from '~/src/UserInterface/Identity/composables/useIdentityNavigation'
import useUserNavigation from '~/src/UserInterface/Identity/composables/useUserNavigation'

const props = defineProps<{
  user: User
}>()

const { userSettingsProfileLocalePath } = useUserNavigation(computed(() => props.user))
const { identityLogoutPageLocalePath } = useIdentityNavigation()
</script>

<template>
  <ui-dropdown
    variant="link"
    dropup
    size="sm"
    no-caret
    toggle-class="text-decoration-none"
  >
    <template #button-content>
      <ui-avatar
        :user
        small
        online
      />
    </template>

    <ui-dropdown-item :to="userSettingsProfileLocalePath">
      {{ $t('pages.settings.profile.profile.page_title') }}
    </ui-dropdown-item>
    <ui-dropdown-divider />
    <ui-dropdown-item :to="identityLogoutPageLocalePath">
      <ui-icon icon="lock" />
      {{ $t('components.organisation.navbar_dropdown.btn_logout') }}
    </ui-dropdown-item>
  </ui-dropdown>
</template>

<script lang="ts" setup>
import { computed, shallowRef, watch } from 'vue'
import EngagementIri from '~/src/Domain/Engagement/EngagementIri'
import EngagementExportDetailsInterface
  from '~/src/Domain/Export/ExportDetails/EngagementExportDetailsInterface'
import type { ExportDetailsProps } from '~/src/Domain/Export/ExportDetails/ExportDetailsInterface'
import type Offcanvas from '~/src/UserInterface/App/components/Ui/Offcanvas/Offcanvas.vue'
import useEngagementState from '~/src/UserInterface/Engagement/composables/useEngagementState'
import useExportState from '~/src/UserInterface/Export/composables/useExportsState'

const offCanvas = shallowRef<InstanceType<typeof Offcanvas>>()
const engagementState = useEngagementState()

const exportState = useExportState()
const exports = computed(() =>
  exportState
    .exportCollection
    .value
    .getExports()
    .map((e) => {
      let description: string | undefined
      if (e.exportDetails instanceof EngagementExportDetailsInterface) {
        description = engagementState.engagements.value.find((eng) => eng['@id'].equalsTo(EngagementIri.fromId((e.exportDetails as EngagementExportDetailsInterface<any, ExportDetailsProps>).engagementId)))?.name
      } else {
        description = undefined
      }

      return {
        id: e['@id'],
        created: e.created,
        fileName: e.fileNameDetails.fileName,
        description,
        original: e,
      }
    })
    .sort((a, b) => b.created.getTime() - a.created.getTime()),
)
watch(exportState.exportCollection, (newValue, oldValue) => {
  if (newValue.getNumberOfExports() > oldValue.getNumberOfExports()) {
    offCanvas.value?.show()
  }
})
</script>

<template>
  <ui-offcanvas
    id="notifications"
    ref="offCanvas"
    class="bg-white"
    placement="start"
    variant="link"
    style="width: 400px"
    :hide-backdrop="false"
  >
    <template #header>
      <ui-offcanvas-header-title class="fw-bold">
        {{ $t('components.notifications.sidebar.title') }}
      </ui-offcanvas-header-title>

      <ui-nav
        tabs
        class="nav-tabs-sm modal-header-tabs ms-auto"
      >
        <ui-nav-item active>
          {{ $t('components.notifications.sidebar.nav_item_exports') }}
        </ui-nav-item>
      </ui-nav>
    </template>

    <template #footer="{ hide }">
      <div class="p-3 border-top d-flex justify-content-end">
        <ui-button
          variant="white"
          @click="hide"
        >
          <ui-icon icon="caret-left" />
        </ui-button>
      </div>
    </template>

    <ui-list-group
      v-if="exports.length > 0"
      flush
      activity
    >
      <ui-list-group-item-activity
        v-for="exportItem in exports"
        :key="exportItem.id.toString()"
        icon="envelope"
        :title="exportItem.fileName"
        :description="exportItem.description ?? ''"
        :time="exportItem.created"
      >
        <template #addendum>
          <div class="col-auto">
            <export-download-button :item="exportItem.original" />
          </div>
        </template>
      </ui-list-group-item-activity>
    </ui-list-group>
    <span v-else>
      {{ $t('components.notification_sidebar.lbl_no_exports_found') }}
    </span>
  </ui-offcanvas>
</template>

<script lang="ts" setup>
import type BetterDateTime from '~/src/Domain/Shared/BetterDate/BetterDateTime'
import useI18n from '~/src/UserInterface/App/composables/Translation/useI18n'

defineProps<{
  icon: string
  title: string
  description: string
  time: BetterDateTime
}>()

const { locale } = useI18n()
</script>

<template>
  <ui-list-group-item>
    <div class="row">
      <div class="col-auto">
        <div class="avatar avatar-sm">
          <div class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
            <ui-icon :icon="icon" />
          </div>
        </div>
      </div>
      <div class="col ms-n2 overflow-hidden">
        <h5 class="mb-1 text-truncate">
          <slot name="title">
            <span :title="title">{{ title }}</span>
          </slot>
        </h5>
        <p class="small text-gray-700 mb-0 text-truncate">
          <slot name="description">
            <span :title="description">{{ description }}</span>
          </slot>
        </p>
        <small class="text-muted">
          {{ time.fromNow(locale) }}
        </small>
      </div>
      <slot name="addendum" />
    </div>
  </ui-list-group-item>
</template>

<template>
  <ui-button
    v-b-toggle.sidebar-notifications
    variant="link"
  >
    <ui-icon
      icon="bell"
      class="fs-2"
    />
  </ui-button>

  <notifications-sidebar id="sidebar-notifications" />
</template>
